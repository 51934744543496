// ==== CORE ==== //

// A simple wrapper for all your custom jQuery;
// everything in this file will be run on every page

(function($) {
  //Menu control
  var header = $('#site-header');
  var navSite = $(header).find('.nav-site-wrapper');
  var triggers = $(header).find('.nav__menu__trigger');
  triggers.on('click', function(e) {
    e.preventDefault();
    navSite.toggleClass('active');
    document.documentElement.classList.toggle('overlay-active');
  });

  //Post header rotating image
  var headerGallery = {
    index: 0,
    containerEl: document.querySelector('.post-intro-img'),
    images: undefined,
    init: function() {
      var gal = this;
      if (gal.containerEl) {
        gal.images = gal.containerEl.querySelectorAll('img');
        if (gal.images.length > 1) {
          gal.activeImage = gal.containerEl.querySelector('img.active');
          gal.index = Array.prototype.indexOf.call(
            gal.activeImage.parentNode.children,
            gal.activeImage
          );
          setInterval(function() {
            if (gal.images[gal.index])
              gal.images[gal.index].classList.remove('active');
            gal.index++;
            if (gal.index >= gal.images.length) gal.index = 0;
            gal.images[gal.index].classList.add('active');
          }, 5000);
        }
      }
      return gal;
    },
  }.init();
})(jQuery);
